.kpi-card-container {
  position: relative;
  z-index: 1;
}

.kpi-card {
  border: 1px solid #165f7f;
  border-radius: 8px;
  padding: 15px;
  background-color: #2a2a2a;
  color: white;
  text-align: center;
  transition: all 0.3s ease;
  min-height: 160px;
}

.kpi-card:hover {
  box-shadow: 0 0 15px rgba(22, 95, 127, 0.3);
  transform: translateY(-2px);
}

.kpi-title {
  font-size: 16px;
  margin-bottom: 8px;
}

.kpi-value {
  font-size: 24px;
  font-weight: bold;
}

.kpi-change {
  margin-top: 8px;
  font-size: 14px;
}

.positive {
  color: #28a745;
}

.negative {
  color: #dc3545;
}

.kpi-description {
  margin-top: 8px;
  font-size: 12px;
  color: #ccc;
}