.pagination-prev,
.pagination-next,
.pagination-active-button,
.pagination-button,
.pagination-chevron {
    box-sizing: border-box;
    width: 3rem;
    height: 3rem;
    padding: 0;
    font-size: 18px;
}

.pagination-prev {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
}

.pagination-next {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}

.pagination-active-button,
.pagination-button {
    padding: 12px;
    text-align: center;
    border: 1px solid #165f7f;
    margin-right: 1rem;
}

.pagination-active-button {
    background-color: #0056b3;;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
}

.pagination-chevron {
    width: 2rem;
    height: 2rem;
}