/* global.css */

/* Style for the top bar */
.top-bar {
  width: 100%;
  display: flex;
  justify-content: space-between; /* Space between logo and user info */
  align-items: center;
  background-color: #383737;
  color: white;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

/* Style for the logo in the top bar */
.logo {
  width: 60px;  /* Adjust the size as needed */
  height: auto;
}

/* Ensure user info and logout button are still styled correctly */
.user-info {
  display: flex;
  gap: 20px;
  margin-right: 20px;
}

.logout-btn {
  padding: 8px 16px;
  background-color: #e63946;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto;
  max-width: 150px;
  white-space: nowrap;
  margin-right: 30px;  /* Add space on the right side of the button */
}

.logout-btn:hover {
  background-color: #c82333;
}

/* Global settings */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #121212;  /* Dark background */
  color: #e0e0e0;  /* Light text color */
}

/* Global button styling */

button {
  background-color: #165f7f;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;  /* Make sure buttons take up full width of their container */
}

button:hover {
  background-color: #0056b3;
}

/* Input fields for forms */
input[type="text"], input[type="email"], input[type="password"] {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #2b2b2b;  /* Darker background for input fields */
  color: #e0e0e0;  /* Light text color for better readability */
}

input[type="text"], input[type="email"], input[type="password"] {
  background-color: #2b2b2b;
  color: #e0e0e0;
}

/* Error and success messages */
.message {
  color: #dc3545;
  text-align: center;
  margin-top: 15px;
}

.message.success {
  color: #28a745;  /* Green for success messages */
}

/* Links */
a {
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}

h2 {
  color: #20bce6;  /* Darkish blue color for the heading */
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}
h1 {
  color: #20bce6;  /* Darkish blue color for the heading */
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #20bce6;
  border-radius: 4px;
}

.date-picker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap:10px ;
}

.date-picker-container label {
  color: white;
  margin-right: 10px;
}


