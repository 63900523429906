.home-container {
  text-align: center;
  margin-top: 4rem;
}

.home-logo {
  width: 200px;
  margin-bottom: 20px;
}


.home-content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.home-text-container {
  text-align: left;
  /* border: solid 1px #e0e0e0; */
  width: 100%;
  max-width: 44rem;
  padding: 2rem;
}

.home-text-container h1 {
  font-size: 2.25rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
  color: #00a3ff;
  text-align: left;
  line-height: 1.2;
}

.home-text-container p {
  font-size: 1.125rem;
  margin-top: 0;
  text-align: left;
  line-height: 1.6;
}

.home-auth-forms {
  /* border: solid 1px #e0e0e0; */
  display: flex;
  flex-direction: column;
  width: 22rem;
  margin-top: 2.5rem;
}

.button-group {
  display: flex;
  justify-content: center;
}

.btn {
  padding: 10px 20px;
  background-color: #165f7f;
  color: white;
  text-decoration: none;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s;
  min-width: 150px;
}

.btn:hover {
  background-color: #0056b3;
}


.auth-dropdown-button {
  display: flex;
  align-items: center;
  background-color: #165f7f;
  color: white;
  text-decoration: none;
  font-size: 22px;
  transition: background-color 0.3s;
  min-width: 150px;
  cursor: pointer;
  text-align: left;
  padding: 0;

  border-radius: 0;
}

.auth-dropdown-button:hover {
  background-color: rgb(3, 104, 211);
}

.auth-dropdown-button p {
  margin: 0;
  padding: 15px 20px;
}

.auth-dropdown-icon {
  margin-left: 1rem;
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
}

.auth-dropdown-icon.open {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.auth-form-container {
  overflow: hidden;
  transition: height 0.5s ease;
  width: full;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  background-color: #2a2a2a;  /* Darker background for the form container */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  color: #20bce6;

  border: solid 1px #165f7f;
}

.auth-form-container.open {
  height: auto;
}

.auth-form-container.closed {
  height: 0;
}

.auth-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.auth-dropdown-container {
  margin-bottom: 1rem;
}

.auth-form button {
  padding: 10px 20px;
  margin-top: 1rem;
  background-color: #165f7f;
  color: white;
  text-decoration: none;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s;
  min-width: 150px;
}

.auth-form input, .auth-form select {
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: solid 1px #165f7f;
}

.auth-form input:focus {
  outline: none;
  border-color: #0056b3;
}

