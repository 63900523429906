.dashboard-container {
    max-width: 90vw;
    margin: 0 auto;
    padding: 20px;
  }
  
  
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: inline-block;
    width: auto;
  }
  
  .react-datepicker-popper {
    z-index: 1001 !important;
  }
  
  .date-picker {
    padding: 8px;
    border: 1px solid #165f7f;
    border-radius: 4px;
    background-color: #2a2a2a;
    color: white;
    margin-right: 10px;
  }
  
  /* Summary Table Styles */
  .summary-table {
    overflow-x: auto;
    margin-bottom: 20px;
    position: relative;
  }
  
  .summary-table table {
    width: 100%;
    border-collapse: collapse;
    background-color: #1c1c1c;
    color: white;
  }
  
  .summary-table th,
  .summary-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #165f7f;
  }
  
  .summary-table th {
    background-color: #165f7f;
    color: white;
  }
  
  .summary-table tr:nth-child(even) {
    background-color: #2a2a2a;
  }

  
  
  /* Charts Container */
  .charts-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .pie-chart {
    position: relative;
    background-color: #1c1c1c;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #165f7f;
    color: white;

    width: 38rem;
  }

  .line-chart {
    position: relative;
    background-color: #1c1c1c;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #165f7f;
    color: white;

    grid-column: 1 / -1
  }

  
  /* Responsive Design */
  @media (max-width: 768px) {
    .charts-container {
      grid-template-columns: 1fr;
    }
  }
  
  /* Top Bar Styles (if not already defined in a global CSS file) */
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1c1c1c;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  .logo {
    height: 40px;
  }
  
  .user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: white;
  }
  
  .logout-btn {
    background-color: #165f7f;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .logout-btn:hover {
    background-color: #0e4a6b;
  }