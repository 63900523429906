.tooltip {
    position: fixed; /* Changed from absolute to fixed */
    background-color: #00a3ff;
    color: white;
    padding: 10px 15px;
    border-radius: 6px;
    font-size: 14px;
    max-width: 280px;
    width: 280px;
    z-index: 10000000; /* Very high z-index */
    text-align: center;
    pointer-events: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    opacity: 0.95;
}

.tooltip::after {
    content: "";
    position: absolute;
    bottom: -8px; /* Changed from top to bottom */
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: #00a3ff transparent transparent transparent;
}