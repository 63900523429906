.dashboard-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* KPI Grid */
.kpi-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

/* Charts Grid */
.charts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
}

.chart-container {
  background-color: #1c1c1c;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #165f7f;
  color: white;
}

.chart-container h3 {
  margin-bottom: 20px;
  color: #20bce6;
}

/* Date Picker Styles */
.react-datepicker {
  font-family: Arial, sans-serif;
  background-color: #2a2a2a;
  color: #ffffff;
  border: 1px solid #165f7f;
  border-radius: 4px;
}

.react-datepicker__header {
  background-color: #1c1c1c;
  border-bottom: 1px solid #165f7f;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #ffffff;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #165f7f;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #20bce6;
  color: #ffffff;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #165f7f;
  color: #ffffff;
}

.react-datepicker__navigation {
  top: 5px;
}

.react-datepicker__navigation--previous {
  border-right-color: #ffffff;
}

.react-datepicker__navigation--next {
  border-left-color: #ffffff;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ffffff;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-bottom-color: #1c1c1c;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
  border-top-color: #1c1c1c;
}

/* Adjust the input field if needed */
.date-picker {
  background-color: #2a2a2a;
  color: #ffffff;
  border: 1px solid #165f7f;
  padding: 8px;
  border-radius: 4px;
}

.date-picker:focus {
  outline: none;
  border-color: #20bce6;
}