.top-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: white;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  .logo {
    height: 40px;
  }
  
  .navigation {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
  
  .navigation a {
    color: #fff;
    font-size: 1.2rem;
    margin: 0 15px;
    text-decoration: none;
  }
  
  .navigation a:hover {
    text-decoration: underline;
  }
  
  .user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .logout-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    width: 150px;
  }
  
  .logout-btn:hover {
    background-color: #0056b3;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 2px; /* Space between username and email */
    white-space: nowrap; /* Prevent wrapping */
  }
  
  .username {
    color: #00a3ff; /* Adjust the color as desired */
    font-weight: bold;
  }
  
  .email {
    color: #038eff; /* Adjust the color as desired */
    font-style: italic;
  }
  