.panel-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: start;
  margin: 0 auto;
  width: 100%;

  background-color: #1c1c1c;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #165f7f;
  color: white;
}

.pie-panel {
  margin: 10px;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.panel {
  margin: 10px;
  width: 34%;
}

.panel-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.pie-panel-chart {
  position: relative;
  background-color: #1c1c1c;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #165f7f;
  color: white;

  margin-top: 1rem;
  margin-bottom: 1rem;

  position: relative;
}

.range-labels {
  position: relative;
  text-align: right;
  margin-top: -3rem; /* Move it up a little */
  margin-bottom: 1.5rem;
}

.range-left,
.range-right {
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  color: #6b8e23;
}

.range-left {
  left: 20%; /* Adjust this value to position closer to the left edge */
}

.range-right {
  right: 12%; /* Adjust this value to position closer to the right edge */
}

.label-top {
  transform: translateY(15px);
}

.label-bottom {
  transform: translateY(-15px);
}

.performance-table-loader-container {
  position: relative;
}

.performance-table {
  overflow-x: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.performance-table table {
  width: 100%;
  border-collapse: collapse;
  background-color: #1c1c1c;
  color: white;
}

.performance-table th,
.performance-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #165f7f;
}

.performance-table th {
  background-color: #165f7f;
  color: white;
}

.performance-table tr:nth-child(even) {
  background-color: #2a2a2a;
}

.metric-grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 15px;
  margin-bottom: 20px;
  align-items: start;
}

.metric {
  background-color: #1c1c1c;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #165f7f;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 3rem;
}

.metric-pie {
  grid-column: 2 / span 2; /* Span across all 3 columns */
  grid-row: 2 / span 2; /* Place it on the second row */
  background-color: #1c1c1c;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #165f7f;
  color: white;
}

.metric-pie-panel-chart {
  display: flex;
  justify-content: center;
  align-items: center;
}

.metric-value {
  margin: 0;
  font-size: 16px;
}

.metric-title {
  margin: 0;
  font-size: 14px;
}

.metric-value {
  font-size: 24px;
  font-weight: bold;
}
