.dashboard-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}


.logo {
  height: 40px;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.logout-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: #0056b3;
}

.dashboard-content {
  margin-top: 40px;
  padding: 20px;
  text-align: center;
}

.dashboard-content h1 {
  font-size: 36px;
  color: #00a3ff;
}

.dashboard-content p {
  font-size: 20px;
  color: #ffffff;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.dashboard-card {
  background-color: #2a2a2a;
  border: 1px solid #165f7f;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 163, 255, 0.3);
}

.dashboard-card h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #00a3ff;
}

.dashboard-card p {
  font-size: 16px;
  color: #cccccc;
}

/* Responsive design */
@media (max-width: 768px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }
}

.integrations-view-wrapper {
  display: flex;
  justify-content: center;

  padding: 2rem 6rem;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.integrations-card {
  background-color: #2a2a2a;
  border: 1px solid #165f7f;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  width: 12rem;
  height: 8rem;
}

.integrations-card p {
  color: #cccccc;
  margin: 0;
}

.infraspeak-img {
  height: 2rem;
  background: #fff;
  padding: 0.5rem;
  border-radius: 5px;
}

.integrations-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 163, 255, 0.3);
}


.integrations-card-active {
  background-color: #2a2a2a;
  border: 1px solid #165f7f;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;

  position: relative;

  width: 100%;
}
.back-icon-button {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  padding: 0;

  width: 3rem;
  height: 3rem;
}

.back-icon {
  width: 3rem;
  height: 3rem;
}

.integrations-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.integrations-form input {
  max-width: 32rem;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #165f7f;
  border-radius: 4px;

}

.input-error {
  border: 1px solid #dc3545 !important;
}

.integrations-form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  width: 10rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  position: relative;
}

.integrations-card-active input:focus {
  outline: none;
  border: 1px solid #00a3ff;
}