  .custom-multi-select {
    position: relative;
    display: inline-block;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  
  .dropdown-button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .dropdown-icon {

    width: 1.5rem;
    height: 1.5rem;
  }

  .dropdown-list {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    max-height: 200px;
    overflow-y: auto;
    width: 24rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    z-index: 1;
  }
  
  .dropdown-option {
    display: flex;
    color: black;
    padding: 8px;
  }
  
  .selected-options {
    margin-top: 10px;
  }
  
  .selected-options ul {
    list-style-type: none;
    padding-left: 0;
  }

  .error-message {
  color: #dc3545 !important;
  margin: 0;
  padding: 0;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2); /* Light grey transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure it is above the content */
  border-radius: 8px;
}

.loader-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.loader-spinner-icon {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}